<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-5">
          <router-link to="/" class="d-flex align-center">
            <v-img class="mb-6" max-width="150" contain :src="appLogo"></v-img>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t('page.forgot_password.forgot_password') }} 🔒
          </p>
          <p class="mb-2">{{ $t('page.forgot_password.description') }}</p>
        </v-card-text>

        <v-card-text>
          <v-form @submit.prevent>
            <v-text-field
              v-model="email.model"
              outlined
              :label="$t('label.email')"
              hide-details="auto"
              class="mb-4"
              :error-messages="$t(email.error, { field: $t('label.email') })"
              :disabled="loading"
              autofocus
            ></v-text-field>
            <v-btn @click="sendMailOnClick" type="submit" block color="primary" :disabled="loading" :loading="loading">
              {{ $t('page.forgot_password.reset_button') }}
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <router-link to="/" class="d-flex align-center text-sm">
            <v-icon size="24" color="primary">
              {{ arrowLeftIcon }}
            </v-icon>
            <span>{{ $t('page.forgot_password.back_to_login') }}</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'

export default {
  name: 'Forgotpassword',
  data: () => ({
    isPasswordVisible: false,
    loading: false,
    email: {
      model: '',
      error: '',
    },

    appName: themeConfig.app.name,
    appLogo: themeConfig.app.logo,
    arrowLeftIcon: mdiChevronLeft,
  }),
  methods: {
    sendMailOnClick: function () {
      this.loading = true

      this.$http
        .post('reset_password_email', { email: this.email.model })
        .then(() => {
          this.$store.commit('notification/notify', {
            text: 'notification.reset_email_send',
            color: 'success',
          })
          this.email.error = ''
        })
        .catch(err => {
          const errors = err.response.data.error
          this.email.error = errors ? errors : ''
        })
        .finally(() => {
          this.email.model = ''
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

